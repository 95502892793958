import axios, { config } from "../core/axios";
import { Headers } from "./generic";
import { assertSuccess } from "../core/assert";

import type {
  CompactSuccess,
  GenericResult,
  MessageResult,
  Result,
} from "../types/result";
import type {
  CreatorEntity,
  PaymentInterface,
  PaymentAllowedCountryCode,
} from "../types/creator";
import type { RawEntity } from "../types/entity";
import { paramsParser } from "../core";

export type AccountCreationBodyStripe = {
  interface: "stripe";
  organizationId: string;
  countryCode: string;
  locale: string;
  accountType: "express" | "standard";
};

export type AccountCreationBodyPaypal = {
  interface: "paypal";
  organizationId: string;
  code: string;
};

export type AccountCreationBody =
  | AccountCreationBodyStripe
  | AccountCreationBodyPaypal;

export class Creator {
  static async getAccount(
    organizationId: string,
    provider: "paypal" | "stripe",
    auth: string,
  ) {
    const resp = await axios<Result<CreatorEntity>>(
      `${config.paymentsUrl}/creator/account/${organizationId}?interface=${provider}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async createAccount(body: AccountCreationBody, auth: string) {
    const resp = await axios<Result<CreatorEntity>>(
      `${config.paymentsUrl}/creator/account/create`,
      {
        method: "POST",
        headers: Headers.Bearer(auth),
        data: body,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async setupAccount(organizationId: string, auth: string) {
    const resp = await axios<
      Result<
        RawEntity<
          "redirect",
          {
            uri: string;
            code: number;
          }
        >
      >
    >(`${config.paymentsUrl}/creator/account/${organizationId}/setup`, {
      method: "POST",
      headers: Headers.Bearer(auth),
    });

    return assertSuccess(resp.data).data;
  }

  static async getValidCountryCodes() {
    const resp = await axios<
      CompactSuccess<{
        data: {
          [code in PaymentAllowedCountryCode]: ("express" | "standard")[];
        };
      }>
    >(`${config.paymentsUrl}/creator/account/countryCodes`);

    return assertSuccess(resp.data).data;
  }

  static async deleteAccount(
    organizationId: string,
    provider: "stripe" | "paypal",
    version: number,
    auth: string,
  ) {
    const params = { interface: provider, version: version };
    const resp = await axios<MessageResult<"Account successfully removed">>(
      `${config.paymentsUrl}/creator/account/${organizationId}${paramsParser(params)}`,
      {
        method: "DELETE",
        headers: Headers.Bearer(auth),
      },
    );

    return resp.data.result === "ok";
  }

  static async connectPaypal(locale: string, auth: string) {
    const resp = await axios.get<GenericResult<{ redirectUri: string }>>(
      `${config.paymentsUrl}/creator/paypal/connect?locale=${locale}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).redirectUri;
  }
}
